/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import themeConfig from "../../themeConfig";
import colors from "../../themeConfig.js";

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// *From Auth - Data will be received from auth provider

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  theme: themeConfig.theme || "light",
  themePrimaryColor: colors.primary,
  themeSecondaryColor: colors.secondary,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
};

export default state;
