import state from "./State";
import mutations from "./Mutation";
import actions from "./Action";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
};
