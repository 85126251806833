export default {
  GET_BRAND_PROFILE(state, payload) {
    state.brandProfile = payload;
  },
  GET_BUSINESS_SUITES(state, payload) {
    state.businessSuites = payload;
  },
  GET_GOOGLE_FONT(state, payload) {
    state.googleFontFamily = payload;
  },

  SET_LAYOUT(state, payload) {
    state.layoutSelected = payload;
  },
  GET_SUITES(state, payload) {
    state.suites = payload;
  },
};
