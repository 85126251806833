/*=========================================================================================
  File Name: moduleEmailMutations.js
  Description: Email Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_PAGES(state, query) {
    state.pages = query;
  },
  UPDATE_PAGES(state, query) {
    state.pages[query.page][query.id] = query.data;
  },
  SET_USER(state, query) {
    state.user = query;
  },
};
