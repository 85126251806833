import RepositoryFactory from "../../apis";
const cmsRepository = RepositoryFactory.cms;
import jwt from "jsonwebtoken";

export default {
  // get client cms
  async getClientCms({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cmsRepository.getClientCms(payload);

        let newData = {};
        let data1 = data.data;
        for (let i = 0; i < data1.length; i++) {
          let interaldata = {};
          if (!newData.hasOwnProperty(data1[i].pageName)) {
            if (data1[i].contentType === "Image") {
              interaldata[data1[i].fieldId] = data1[i].content;
            } else if (data1[i].contentType === "Cards") {
              console.log("cards", data1[i].loop);

              interaldata[data1[i].fieldId] = data1[i].loop;
            } else {
              interaldata[data1[i].fieldId] = data1[i].content;
            }

            newData[data1[i].pageName] = interaldata;
          } else {
            if (data1[i].contentType === "Image") {
              interaldata[data1[i].fieldId] = data1[i].content;
            } else if (data1[i].contentType === "Cards") {
              // console.log("cards", data1[i].loop);
              interaldata[data1[i].fieldId] = data1[i].loop;
            } else {
              interaldata[data1[i].fieldId] = data1[i].content;
            }

            Object.assign(newData[data1[i].pageName], interaldata);
          }
        }
        // console.log(newData, data);
        commit("SET_PAGES", newData);
        resolve(data);
        // console.log(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  async getClientCmsById({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cmsRepository.getClientCms(payload);
        resolve(data);
        // console.log(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  async updateClientCms({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cmsRepository.updateClientCms(payload);
        resolve(data);
        // console.log(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  async getUser({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cmsRepository.getUser(payload);
        if (data.data && data.data[0].token) {
          let token = data.data[0].token;
          let claim = jwt.verify(token, "p4sta.w1th-b0logn3s3-s@uceundefined");
          if (claim) {
            commit("SET_USER", data.data[0]);
          } else {
            commit("SET_USER", {});
          }
          resolve(data);
        }

        // console.log(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  async uploadImage({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await cmsRepository.uploadImage(payload);
        resolve(data);

        // console.log(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
