export default {
  ADD_MENU(state, payload) {
    state.menuData = payload;
  },
  SET_DETAILED_CATEGORY(state, payload) {
    state.detailedCategory = payload;
  },
  addTempData(state, payload) {
    state.tempData = payload;
  },
  selectedData(state, payload) {
    state.selectedDatas = payload;
  },
  updateImage(state, payload) {
    state.tempData.map((x, i) => {
      if (x._id == payload.key) {
        state.tempData[i].img = payload.data;
      }
    });
    console.log(state.tempData);
  },
};
